.collapsible .content {
  padding: 6px;
  background-color: #eeeeee;
}

.collapsible .header {
  background-color: #dddddd;
  padding: 6px;
  cursor: pointer;
}

.map-component,
.map2-component,
.chartcontent-component,

canvas {
  image-rendering: optimizeSpeed;
  image-rendering: -moz-crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  image-rendering: -o-crisp-edges;
  image-rendering: crisp-edges;
  image-rendering: -webkit-optimize-contrast;
  -ms-interpolation-mode: nearest-neighbor;
}