@font-face {
    font-family: 'ProximaNova';

    src: url('~fonts/ProximaNova-Regular.ttf') format('truetype');

}

@font-face {
    font-family: 'soehne-breit';
    src: url('./fonts/soehne-breit-web-dreiviertelfett 1.woff2') format('woff2');
      
    font-weight: normal;
    font-style: normal;
  }
  

#root {

    /* font-family: 'Giga Sans', Arial, sans-serif; */
    /* font-family: 'Söhne Breit', Söhne Breit, Söhne Breit; */

     font-family: 'ProximaNova', Arial, sans-serif, 'soehne-breit' ;
}

@keyframes move-plum {
    0% {
        transform: scale(1) translate(0%, 0%);
    }
    25% {
        transform: scale(1.02) translate(0%, 2.5%) rotate(20deg);
    }
    50% {
        transform: scale(1.04) translate(0%, 2%);
    }
    75% {
        transform: scale(1.02) translate(0%, 1%);
    }
    100% {
        transform: scale(1) translate(0%, 0%);
    }
}

@keyframes move-pumpkin {
    0% {
        transform: scale(1) translate(0%, 0%);
    }
    25% {
        transform: scale(1.02) translate(0%, 2.5%) rotate(20deg);
    }
    50% {
        transform: scale(1.04) translate(0%, 2%);
    }
    75% {
        transform: scale(1.02) translate(0%, 1%);
    }
    100% {
        transform: scale(1) translate(0%, 0%);
    }
}

@keyframes move-pea {
    0% {
        transform: scale(1) translate(0%, 0%);
    }
    25% {
        transform: scale(1.02) translate(0%, 2.5%) rotate(20deg);
    }
    50% {
        transform: scale(1.04) translate(0%, 2%);
    }
    75% {
        transform: scale(1.02) translate(0%, 1%);
    }
    100% {
        transform: scale(1) translate(0%, 0%);
    }
}

@keyframes move-petroleum {
    0% {
        transform: scale(1) translate(0%, 0%);
    }
    25% {
        transform: scale(1.02) translate(0%, 2.5%) rotate(20deg);
    }
    50% {
        transform: scale(1.04) translate(0%, 2%);
    }
    75% {
        transform: scale(1.02) translate(0%, 1%);
    }
    100% {
        transform: scale(1) translate(0%, 0%);
    }
}

@keyframes move-brown {
    0% {
        transform: scale(1) translate(0%, 0%);
    }
    25% {
        transform: scale(1.02) translate(0%, 2.5%) rotate(20deg);
    }
    50% {
        transform: scale(1.04) translate(0%, 2%);
    }
    75% {
        transform: scale(1.02) translate(0%, 1%);
    }
    100% {
        transform: scale(1) translate(0%, 0%);
    }
}

.wooble-bubble-wrapper svg g {
    mix-blend-mode: soft-light;
}

.subsection-inner .flex.justify-center {
    justify-content: flex-start;
}

.subsection-inner * {
    overflow: visible;
}